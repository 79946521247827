import React from "react";
import styles from "./Volt.module.scss";
import volts, { VoltType } from "./volts";

interface VoltProps {
  position: "top" | "bottom";
  className?: string;
  name: VoltType;
}

const Volt: React.FunctionComponent<VoltProps> = ({ className, name, position, ...props }) => (
  <div className={`${styles.volt} ${className} ${styles[position]}`} {...props}>
    <img className={`${styles.voltImage}`} src={volts[name]} />
  </div>
);

export default Volt;
