import VoltBottom01 from "../../../assets/images/volts/volt-bottom-01.png";
import VoltBottom02 from "../../../assets/images/volts/volt-bottom-02.png";
import VoltBottom03 from "../../../assets/images/volts/volt-bottom-03.png";
import VoltBottom04 from "../../../assets/images/volts/volt-bottom-04.png";
import VoltTop01 from "../../../assets/images/volts/volt-top-01.png";
import VoltTop02 from "../../../assets/images/volts/volt-top-02.png";
import VoltTop03 from "../../../assets/images/volts/volt-top-03.png";

const volts = {
  VoltBottom01,
  VoltBottom02,
  VoltBottom03,
  VoltBottom04,
  VoltTop01,
  VoltTop02,
  VoltTop03,
};

export default volts;

export type VoltType = keyof typeof volts;
